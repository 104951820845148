import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { Constants, FinanceAccountDefaultFilters } from "config/constants";
import { EMPTY_DATA } from "config/main";
import { useEffect, useState } from "react";
import { Tooltip, Whisper } from "rsuite";
import CreateTransactionModal from "./CreateTransactionModal";
import StockOperationsDrawer from "../../inventory/misc/StockOperationsDrawer";
import { exportFile } from "lib/export-table";
import classNames from "classnames";
import CreateButton from "components/DataTable/CreateButton";
import DeletedBy from "components/mini/DeletedBy";
import { useData } from "hooks/useData";
import _ from "lodash";
import Money from "components/mini/Money";
import WhisperWithoutIcon from "components/mini/WhisperWithoutIcon";
import ProductReturnDrawer from "components/Drawers/ProductReturnDrawer/ProductReturnDrawer";
import ReturnedSuppliesProductsDrawer from "../../inventory/misc/ReturnedSuppliesProductsDrawer";
import CellBtn from "components/DataTable/CellButton";
import { QuickLinks } from "components/Header/QuickLinks";
import CIcon from "components/CIcon";
import { iArrowDown, iArrowUp, iCommentProcessingOutline, iExchange } from "assets/icons/Icons";
import TransactionPageFilterDrawer from "./TransactionPageFilterDrawer";
import { RowDataType } from "rsuite-table/src/@types/common";
import ToggleFilterDrawer from "components/DataTable/Filters/ToggleFilterDrawer";
import { Colors } from "config/colors";
import { format, isValid } from "date-fns";

const transactionColors: Record<TransactionType, string> = {
    IN: Colors.EmeraldGreen,
    OUT: Colors.CarmineRed,
    TRANSFER: Colors.JetBlack,
    SUPPLIER_PAY: Colors.JetBlack,
};

const transactionIcons = {
    IN: <CIcon path={iArrowDown} size={0.6} className="mr-1 mt-1" style={{ color: transactionColors.IN }} />,
    OUT: <CIcon path={iArrowUp} size={0.6} className="mr-2 mt-1" style={{ color: transactionColors.OUT }} />,
    TRANSFER: (
        <CIcon
            path={iExchange}
            size={0.6}
            className="mr-1 mt-1"
            style={{ color: transactionColors.TRANSFER }}
            rotate={90}
        />
    ),
};

const SupplierTransactionPage: FCC = () => {
    const [hasDeletedBy, setHasDeletedBy] = useState<boolean>(false);
    const [op, setOp] = useState<IOperation>();

    const dp = useDataProvider({
        url: "finance/transaction",
        useUrl: true,
        useDates: true,
        extraFilterKeys: ["description", "trash", "category_id", "user_id"],
        filters: {
            indicator: ["indicator", 1],
            supplier_id: ["supplier_id", "!=", ""],
        },
        with: ["category", "balance", "staff", "user", "operation.supplier", "from_balance", "supplier", "user_delete"],
        sort: ["operated_at", "-1"],
    });

    const rd = useDrawer();
    const sd = useDrawer({ backdrop: false, overflowAuto: true });
    const returnsDrawer = useDrawer();
    const suppliesReturnsDrawer = useDrawer();
    const summary = useData<any>("report/finance/total", {
        params: { date: dp.params.all?.date },
        filters: _.values(dp.filters.all),
    });

    const filter = useDrawer();

    const cm = useColumns<any>("finance/supplier-transaction", [
        {
            title: t("data_id"),
            key: "id",
            width: 100,
            align: "right",
            flexGrow: undefined,

            render: e => (
                <div className="d-flex">
                    {e.id}
                    {e.description ? (
                        <Whisper placement="right" trigger="hover" speaker={<Tooltip>{e.description}</Tooltip>}>
                            <span>
                                <CIcon
                                    path={iCommentProcessingOutline}
                                    className="text-primary"
                                    style={{ marginTop: "3px", marginLeft: "7px" }}
                                    size={"14"}
                                />
                            </span>
                        </Whisper>
                    ) : null}
                </div>
            ),
        },
        {
            key: "operated_at",
            type: "datetime",
            align: "center",
            minWidth: 135,
            title: t("operation"),
            render: (d: any) => {
                const date = new Date(d.operated_at);

                return (
                    <div className="tw-flex">
                        <div className="tw-w-5">{(transactionIcons as any)[d.type]}</div>{" "}
                        <div>{isValid(date) ? format(date, "MMMM dd") : EMPTY_DATA}</div>
                    </div>
                );
            },
        },
        {
            key: "created_at",
            type: "datetime",
            align: "center",
            minWidth: 135,
            multiline: true,
            reverse: true,
        },
        {
            key: "user.username",
            minWidth: 180,
            title: t("transaction_by"),
        },
        {
            key: "deleted_at",
            type: "datetime",
            minWidth: 105,
            hide: hasDeletedBy,

            render: d => <DeletedBy {...d} />,
        },
        { key: "user_delete.username", title: t("deleted_by"), hide: hasDeletedBy },
        { key: "category.name", title: t("category") },
        {
            key: "supplier.name",
            title: t("supplier"),

            align: "left",
            render: d =>
                d.supplier?.deleted_at ? (
                    <span style={{ color: Colors.BrightRed }}>{d.supplier?.name}</span>
                ) : (
                    d.supplier?.name || ""
                ),
        },
        {
            key: "reference",
            render: d => {
                return d.operation && d.operation?.supplier ? (
                    <CellBtn
                        action={() => {
                            rd.hide();
                            returnsDrawer.hide();
                            suppliesReturnsDrawer.hide();
                            setOp(d.operation);
                            sd.setDataId(d.operation.id);
                        }}
                        label={`#${d.operation_id}`}
                    />
                ) : (
                    EMPTY_DATA
                );
            },
        },
        { key: "description", render: e => <WhisperWithoutIcon description={e.description} /> },

        {
            key: "before_amount",
            type: "money",
            numberRoundingLength: 2,
            flexGrow: undefined,
            getStyle: (d: ITransaction) => ({ color: Colors.MidGray }),
            hide: true,
        },
        {
            key: "amount",
            type: "money",
            flexGrow: undefined,
            getStyle: (d: ITransaction) => ({ color: transactionColors[d.type] }),

            summary: () => <Money colored value={summary.data && summary.data[0]?.total_diff} />,
        },
        {
            key: "after_amount",
            type: "money",
            numberRoundingLength: 2,
            minWidth: 150,
            flexGrow: 1.4,
            getStyle: (d: ITransaction) => ({ color: Colors.MidGray }),

            hide: true,
        },
        { key: "type", render: (d: ITransaction) => (Constants.transactionTypes as any)[d.type] },
        optionsColumn({
            dp,
            preventDelete: (d: ITransaction) => !!(d.receipt_id || d.operation),
            editable: (d: ITransaction) => !!(d.receipt_id || d.operation),
            delete: "finance/transaction/:id",
            canDelete: "finance_transaction_delete",
        }),
    ]);

    const modal = useDrawer();

    useEffect(() => {
        if (!modal.dataId) {
            dp?.revalidate();
        }
        // eslint-disable-next-line
    }, [modal.dataId]);

    return (
        <div className="h-100">
            {useTitle(t("transactions"), dp.total)}

            <QuickLinks
                inHeader={false}
                links={[
                    {
                        link: "/finance/transactions",
                        label: t("transactions"),
                    },
                    {
                        link: "/finance/customer-transactions",
                        label: t("customer_transactions"),
                    },
                    {
                        link: "/finance/supplier-transactions",
                        label: t("supplier_transactions"),
                        active: true,
                    },
                ]}
            />

            <HeaderRight
                cm={cm}
                date
                dp={dp}
                reload
                print
                export={() => exportFile(dp, cm.columns, t("transactions"))}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <CheckListFilter
                        pickerProps={{ searchable: false }}
                        fields="type"
                        options={[
                            { label: t("income"), value: "IN" },
                            { label: t("expense"), value: "OUT" },
                            { label: t("transfer"), value: "TRANSFER" },
                        ]}
                    />

                    <RemoteCheckListFilter
                        fields="supplier_id"
                        searchField="name"
                        remote="supplier"
                        pickerProps={{ placeholder: t("supplier") }}
                    />

                    <RemoteCheckListFilter
                        fields="balance_id"
                        searchField="name"
                        extraFields={["from_balance_id"]}
                        remote={{
                            url: "finance/balance",
                            filters: FinanceAccountDefaultFilters,
                        }}
                    />
                    <ToggleFilterDrawer isOpen={filter.isOpen} onChange={val => filter.setIsOpen(val)} />
                </div>
                <CreateButton to={() => modal.setDataId(0)} canCreate={"finance_transaction_create"} />
            </FiltersContainer>

            <ReceiptDrawer {...rd} />
            <StockOperationsDrawer
                {...sd}
                op={op}
                title={t("total_supply") + " #" + sd.dataId}
                cols={["product.name", "quantity", "cost", "total_cost"]}
            />
            <CreateTransactionModal {...modal} revalidate={dp.revalidate} />
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
            <ProductReturnDrawer {...returnsDrawer} />
            <ReturnedSuppliesProductsDrawer {...suppliesReturnsDrawer} />
            <TransactionPageFilterDrawer showOnPage="supplierPage" {...filter} dp={dp} setDeletedBy={setHasDeletedBy} />
        </div>
    );
};

export default SupplierTransactionPage;
