import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "components/Form/TextFormGroup";
import { useFormTitle } from "components/Header/Title";
import { useCForm } from "hooks/useCForm";
import { useData } from "hooks/useData";
import { splitAbilities } from "lib/data-utils";
import { dummyRole, RoleModel } from "models/UserModel";

import { Checkbox } from "rsuite";
import _ from "lodash";
import React from "react";
import CIcon from "components/CIcon";
import { iInfoOutline } from "assets/icons/Icons";
import NewAbilityCheckFormGroup from "components/Form/NewAbilityCheckFormGroup";
import SubmitButtonGroup from "components/Form/SubmitButtonGroup";
import { useAppSelector } from "hooks/useRedux";

export interface ISplitAbilities {
    terminal: IAbility[];
    web: IAbility[];
}

const NewRoleEditPage: FCC = () => {
    const isSidebarExpanded = useAppSelector(state => state.ui.isSidebarExpanded);

    const form = useCForm({
        url: "role",
        with: ["abilities"],
        model: RoleModel(),
        dummyData: dummyRole,
        prepareForSubmit: (d?: any) => ({
            name: d.name,
            title: d.name,
            abilities: JSON.stringify(d.abilities),
        }),
        refine: (d?: any) => ({
            ...d,
            abilities: d.abilities.map((a: IAbility) => a.name),
        }),
    });
    const ability = form.form.watch("abilities");

    const abilities = useData<IAbility[]>("ability", { params: { limit: 999 } });

    const refinedAbilities = _.sortBy(abilities.data, ({ name }) => (name === "web_access" ? 0 : 1))
        ?.filter(a => {
            return a.name !== "log_access";
        })
        .reduce(splitAbilities, {}) as ISplitAbilities;

    const toggleGeneralPermission = (value?: string | number, checked?: boolean) => {
        let refined: string[] = ability;
        if (checked && value) {
            refined.push(value.toString());
        } else {
            refined = refined.filter(ve => ve !== value);
        }
        form.form.setValue("abilities", refined);
    };

    return (
        <div>
            {useFormTitle(form.id, t("staff"))}
            <div
                className="tw-flex tw-items-center tw-justify-between tw-px-3 tw-py-3.5 tw-border-0 tw-border-b tw-border-t tw-border-solid tw-border-gray-200"
                style={{
                    maxWidth: `calc(100vw - ${isSidebarExpanded ? "184px" : "56px"})`,
                }}
            >
                <div className="tw-h-8 tw-flex tw-items-center tw-text-text-500 text-body1-regular tw-gap-1 tw-px-2 tw-py-1 tw-bg-primary-50 tw-rounded-lg">
                    <CIcon path={iInfoOutline} size="24" />
                    <p> {t("roles_edit_callout_message_create_btn")}</p>
                </div>
                <SubmitButtonGroup isLoading={form.isLoading} onSubmit={form.onSave} label={t("save")} />
            </div>

            <CForm form={form.form} onSave={form.onSave} formProps={{ layout: "vertical" }}>
                <div className="tw-p-3">
                    <div className="tw-grid tw-grid-cols-[1fr_auto_1fr] tw-gap-8 tw-p-4 tw-border-solid tw-rounded-lg tw-border tw-border-basic-metal-grey-500">
                        <TextFormGroup
                            name="name"
                            errorPlacement="bottomStart"
                            focus={true}
                            controlWrapperProps={{
                                style: { width: "100%" },
                            }}
                            compProps={{
                                placeholder: t("name"),
                                style: { width: "100%" },
                            }}
                            labelProps={{
                                className: "tw-text-text-800 text-caption1-medium",
                            }}
                        />
                        <div className="tw-w-px tw-bg-basic-grey tw-h-full" />
                        <div className="tw-flex tw-flex-col tw-gap-2">
                            <span className="tw-text-text-800 text-caption1-medium">{t("user_type")}</span>
                            <div className="tw-flex tw-flex-col tw-gap-4">
                                <Checkbox
                                    value={"field_seller"}
                                    test-id="field_seller"
                                    onChange={toggleGeneralPermission}
                                    checked={ability?.indexOf("field_seller") !== -1}
                                    className="text-body2-regular tw-text-text-600"
                                >
                                    <TText tkey="field_seller" />
                                </Checkbox>
                                <Checkbox
                                    value={"field_courier"}
                                    test-id="field_courier"
                                    onChange={toggleGeneralPermission}
                                    checked={ability?.indexOf("field_courier") !== -1}
                                    className="text-body2-regular tw-text-text-600"
                                >
                                    <TText tkey="field_courier" />
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                    <div className="tw-mt-3">
                        <NewAbilityCheckFormGroup
                            name="abilities"
                            options={refinedAbilities}
                            abilities={abilities.data as any}
                        />
                    </div>
                </div>
                {/*<JsonView collapsed />*/}
            </CForm>
        </div>
    );
};

export default NewRoleEditPage;
